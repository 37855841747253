import instance from '@/api/request';
/**
 * @description 执行
 *
 * @tags script
 * @name PostScript
 * @request POST:/script/Script
 * @secure
 */
export const postScript = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/kernel/execute`,
    method: 'POST',
    data: payload,
    needToken: true,
    ...params,
});
/**
 * 获取代码提示
 * @param payload
 * @param params
 * @returns
 */
export const completionScript = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/script/completion`,
    method: 'POST',
    data: payload,
    needToken: true,
    ...params,
});
/**
 * 变量列表
 * @param params
 * @returns
 */
export const variableExplorer = (params = {}) => instance.request({
    url: `comfortuni/gugu/kernel/variable-explorer`,
    method: 'GET',
    needToken: true,
    ...params,
});
/**
 * 执行sql语句
 * @param payload
 * @param params
 * @returns
 */
export const sqlQuery = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/script/execute`,
    method: 'POST',
    data: payload,
    needToken: true,
    ...params,
});
export const completion = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/script/completion`,
    method: 'POST',
    data: payload,
    needToken: true,
    ...params,
});
/**
 * 创建notebook
 * @param payload
 * @param params
 * @returns
 */
export const createNotebook = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/files/notebooks/create?file_name=${payload.name}.ipynb&parent_id=`,
    method: 'POST',
    needToken: true,
    ...params,
});
/**
 * 删除notebook
 * @param payload
 * @param params
 * @returns
 */
export const deleteNotebook = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/files/notebooks/delete?id=${payload.id}`,
    method: 'DELETE',
    needToken: true,
    ...params,
});
/**
 * 获取当前某个notebook
 * @param payload
 * @param params
 * @returns
 */
export const getNotebook = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/files/notebooks/fetch?id=${payload.id}`,
    method: 'GET',
    needToken: true,
    ...params,
});
/**
 * 获取所有notebook
 * @param params
 * @returns
 */
export const getAllNotebooks = (params = {}) => instance.request({
    url: `comfortuni/gugu/files/notebooks/files`,
    method: 'GET',
    needToken: true,
    ...params,
});
/**
 * 更新某个notebook
 * @param payload
 * @param params
 * @returns
 */
export const updateNotebooks = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/files/notebooks/update?id=${payload.id}`,
    method: 'PUT',
    needToken: true,
    data: payload.data,
    ...params,
});
/**
 * 上传文件或文件夹
 * @param data
 * @param params
 * @returns
 */
export const uploadFile = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/files/upload`,
    method: 'POST',
    needToken: true,
    data: data,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
    ...params,
});
/**
 * 新增资源
 * @param data
 * @param params
 * @returns
 * url_mapping
 */
export const addResources = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/resources`,
    method: 'POST',
    needToken: true,
    data: data,
    ...params,
});
/**
 * 查询所有文件
 * @returns
 */
export const files = (params = {}) => instance.request({
    url: `comfortuni/gugu/files`,
    method: 'GET',
    needToken: true,
    ...params,
});
/**
 * 删除文件
 * @param id
 * @param params
 * @returns
 */
export const deleteFiles = (id, params = {}) => instance.request({
    url: `comfortuni/gugu/files/delete?id=${id}`,
    method: 'DELETE',
    needToken: true,
    ...params,
});
/**
 * 重命名文件
 * @param payload
 * @param params
 * @returns
 */
export const renameFiles = (payload, params = {}) => instance.request({
    url: `comfortuni/gugu/files/rename?id=${payload.id}&name=${payload.name}`,
    method: 'POST',
    needToken: true,
    ...params,
});
/**
 * 移动文件
 * @param id
 * @param params
 * @returns
 */
export const moveToNotebook = (id, params = {}) => instance.request({
    url: `comfortuni/gugu/files/move-to-notebook?id=${id}`,
    method: 'POST',
    needToken: true,
    ...params,
});
/**
 * 下载文件
 * @param id
 * @param params
 * @returns
 */
export const downloadFileGet = (id, params = {}) => instance.request({
    url: `comfortuni/gugu/files/download?id=${id}`,
    method: 'GET',
    needToken: true,
    ...params,
});
/**
 * 获取文件url
 * @param name
 * @param params
 * @returns
 */
export const getUrl = (name, params = {}) => instance.request({
    url: `comfortuni/gugu/internal/url_mapping?name=${name}`,
    method: 'GET',
    needToken: true,
    ...params,
});
/**
 * 获取所有节点
 * @param name
 * @param params
 * @returns
 */
export const getNodes = (params = {}) => instance.request({
    url: `comfortuni/gugu/kernel/nodes`,
    method: 'GET',
    needToken: true,
    ...params,
});
/**
 * 获取当前节点的kernel
 * @param body
 * @param params
 * @returns
 */
export const getkernels = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/kernel/kernel-list`,
    method: 'GET',
    params: data,
    needToken: true,
    ...params,
});
/**
 * 开启新的kernel
 * @param body
 * @param params
 * @returns
 */
export const startkernels = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/kernel/start`,
    method: 'POST',
    data: data,
    needToken: true,
    ...params,
});
/**
 * 选择kernel
 * @param body
 * @param params
 * @returns
 */
export const selectKernel = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/kernel/select`,
    method: 'POST',
    data: data,
    needToken: true,
    ...params,
});
/**
 * 选择kernel
 * @param body
 * @param params
 * @returns
 */
export const shutdownKernel = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/kernel/shutdown`,
    method: 'POST',
    data: data,
    needToken: true,
    ...params,
});
/**
 * 获取所有资源
 * @param data
 * @param params
 * @returns
 */
export const resources = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/resources`,
    method: 'GET',
    params: data,
    needToken: true,
    ...params,
});
/**
 * 删除资源
 * @param data
 * @param params
 * @returns
 */
export const delResources = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/resources`,
    method: 'DELETE',
    data: data,
    needToken: true,
    ...params,
});
/**
 * 编辑资源
 * @param data
 * @param params
 * @returns
 */
export const putResources = (data, params = {}) => instance.request({
    url: `comfortuni/gugu/resources`,
    method: 'PUT',
    needToken: true,
    data: data,
    ...params,
});
export const logout = () => instance.request({
    url: `comfortuni/gugu/logout`,
    method: 'POST',
    needToken: true,
});
export const getShare = (id) => instance.request({
    url: `comfortuni/gugu/files/notebooks/share/${id}`,
    method: 'GET',
    needToken: true,
});
export const share = (data) => instance.request({
    url: `comfortuni/gugu/files/notebooks/share`,
    data: data,
    method: 'POST',
    needToken: true,
});
export const getTemplate = (data) => instance.request({
    url: `comfortuni/gugu/template`,
    method: 'GET',
    params: data,
    needToken: true,
});
export const createTemplate = (data) => instance.request({
    url: `comfortuni/gugu/template`,
    method: 'POST',
    data: data,
    needToken: true,
});
export const delTemplate = (data) => instance.request({
    url: `comfortuni/gugu/template?template_id=${data.template_id}`,
    method: 'DELETE',
    data: data,
    needToken: true,
});
export const downloadClient = (id) => instance.request({
    url: `comfortuni/gugu/client/download?client_id=${id}`,
    method: 'GET',
    needToken: true,
    responseType: 'blob',
    headers: {
        'Content-Type': 'application/octet-stream',
    },
});
export const getClient = () => instance.request({
    url: `comfortuni/gugu/client`,
    method: 'GET',
    needToken: true,
});
export const renameFilePUT = (params) => instance.request({
    url: `comfortuni/gugu/files/rename?id=${params.id}&name=${params.name}`,
    method: 'PUT',
    needToken: true,
});
export const pyPackageInstall = (params) => instance.request({
    url: `comfortuni/gugu/package/install`,
    method: 'POST',
    data: params,
    needToken: true,
});
export const pyPackageUninstall = (params) => instance.request({
    url: `comfortuni/gugu/package/uninstall`,
    method: 'POST',
    data: params,
    needToken: true,
});
export const pyPackageList = (params) => instance.request({
    url: `comfortuni/gugu/package/list?node_id=${params.node_id}`,
    method: 'GET',
    needToken: true,
});
