import { defineStore } from 'pinia';
const useAiSocketStore = defineStore('aiSocket', {
    state: () => {
        return {
            socketInstance: null,
            messageListFun: null,
            selectCellIndex: [],
        };
    },
    getters: {},
});
export default useAiSocketStore;
