// websocket-wrapper.ts
class WebSocketWrapper {
    constructor(url) {
        // 存储WebSocket服务端的地址
        Object.defineProperty(this, "url", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 存储WebSocket实例
        Object.defineProperty(this, "socket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 连接打开时的回调函数，类型为无参数且无返回值的函数
        Object.defineProperty(this, "onOpenCallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 收到消息时的回调函数，类型为接收一个字符串参数且无返回值的函数，因为通常接收的消息为文本格式
        Object.defineProperty(this, "onMessageCallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 连接关闭时的回调函数，类型为接收一个CloseEvent类型参数且无返回值的函数
        Object.defineProperty(this, "onCloseCallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 出现错误时的回调函数，类型为接收一个Event类型参数且无返回值的函数
        Object.defineProperty(this, "onErrorCallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.url = url;
        this.socket = null;
        this.onOpenCallback = null;
        this.onMessageCallback = null;
        this.onCloseCallback = null;
        this.onErrorCallback = null;
    }
    connect() {
        this.socket = new WebSocket(this.url);
        this.socket.onopen = () => {
            if (this.onOpenCallback) {
                this.onOpenCallback();
            }
        };
        this.socket.onmessage = (event) => {
            if (this.onMessageCallback) {
                // 这里假设接收的消息为文本格式，调用回调函数并传入消息内容
                this.onMessageCallback(event.data);
            }
        };
        this.socket.onclose = (event) => {
            if (this.onCloseCallback) {
                this.onCloseCallback(event);
            }
        };
        this.socket.onerror = (error) => {
            if (this.onErrorCallback) {
                this.onErrorCallback(error);
            }
        };
    }
    send(message) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            if (typeof message === 'string') {
                this.socket.send(message);
            }
            else {
                this.socket.send(JSON.stringify(message));
            }
        }
    }
    onOpen(callback) {
        this.onOpenCallback = callback;
    }
    onMessage(callback) {
        this.onMessageCallback = callback;
    }
    onClose(callback) {
        this.onCloseCallback = callback;
    }
    onError(callback) {
        this.onErrorCallback = callback;
    }
}
export default WebSocketWrapper;
